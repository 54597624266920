import React from 'react'
import HeroText from '../HeroText/HeroText'
import './Home.css'

export default function Home() {
  return (
    <div className='Home'>
      <HeroText />
    </div>
  )
}
